.galleryItem img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
    transition: var(--transitionX);
}

.galleryItem img:hover {
    transition: var(--transitionX);
    scale: 1.2;
    border-radius: 5px;
    z-index: 999;
}

.galleryContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2rem;
    transition: var(--transition);
}

.galleryWrapper {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10% 10%;
}

.filterItem {
    margin-top: 4rem;
}

.filterItem ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    gap: 20px;
    margin-bottom: 30px;
    transition: var(--transition);

}

.filterItem ul button {
    background: var(--color-primary);
    color: #fff;
    padding: 8px 20px;
    text-transform: uppercase;
    border: navajowhite;
    font-size: 12px;
    font-weight: bold;
    border-radius: 20px; 
    transition: var(--transition);
}

.filterItem ul button:hover {
    background: black;
    cursor: pointer;
}



/*-----------------------------------*/


.lk-container {
    width: 95%;
    display: flex;
    min-height: 100vh;
    position: relative;
    margin: 0 auto;
    margin-bottom: 8rem;
}

.lk-container .linkyt-container {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    gap: 15px;
    margin: 20px auto;
}

.lk-container .linkyt-container .link-media {
    height: 300px;
    width: 100%;
    border-radius: 20px;
    border: 2px solid var(--color-white);
    overflow: hidden;
    cursor: pointer;
}

.lk-container .linkyt-container .link-media :is(video, img) {
    height: 100%;
    width: 100%;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.359) 10px 20px 59px;
    transition: all 0.3s linear;
}

.lk-container .linkyt-container .link-media:hover :is(video, img) {
    scale: 1.1;
}

.lk-container .popup-media {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(8px);
    width: 100%;
    height: 100%;
}

.lk-container .popup-media :is(video, img) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid white;
    display: block;
    border-radius: 10px;
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
}

.lk-container .popup-media span {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 3rem;
    font-weight: 400;
    cursor: pointer;
    color: #fff;
    user-select: none;
}


/*============ MEDIA QUERIES (MEDIUN DEVICES) ============*/
@media screen and (max-width: 1024px) {
    
}

/*============ MEDIA QUERIES (SMALL DEVICES) ============*/
@media screen and (max-width: 600px) {
    .galleryContainer {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    .galleryWrapper {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    .filterItem ul {
        gap: 10px;
    }
    .lk-container .linkyt-container .link-media {
        height: 100%;
    }
}

