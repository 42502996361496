
.navbar {
    top: 0.5rem;
    position: fixed;
    z-index: 999;
    width: 100%;
}

.h-container {
    display: flex;
    justify-content: space-between;
    background: black;
    padding: 0px 2rem;
    align-items: center;
    border-radius: 100px;
    position: relative;
}

.nav__toggle-btn {
    display: none;
    cursor: pointer;
}

.h-container .logo1 img {
    margin-top: 9px;
    width: 8rem;
}

#a{
    font-size: 13px;
    font-weight: bold;
    color: white;
    transition: var(--transition);
}

#a:hover {
    color: var(--color-primary);
}

.h-menu {
    display: flex;
    text-decoration: none;
    gap: 4rem;
    transition: var(--transition);

}

.h-menu li {
    transition: var(--transition);
    color: var(--color-primary);
}    

.h-menu li:hover {
    scale: 1.2;
}

.active-nav {
    background: var(--color-primary); 
    border-radius: 50px;
    padding: 6px 12px;
    transition: all 0.5s ease-in-out;
}
.nav__toggle-btn svg:hover {
    scale: 1.2;
}
 
.h-socials a {
    color: var(--color-white);
    font-size: 20px;
}

.h-container .h-socials {
    margin-top: 6px;
}

.h-socials {
    display: flex;
    gap: 1rem;
}

/*============ MEDIA QUERIES (MEDIUN DEVICES) ============*/
@media screen and (max-width: 1024px) {
    .nav__toggle-btn{
        display: inline-block;
        background: transparent;
        font-size: 1.5rem;
        transition: var(--transition);

    }
    .nav__toggle-btn svg {
        color: var(--color-white);
        transition: var(--transitionX);

    }
    .h-menu {
        position: absolute;
        height: 500%;
        align-items: center;
        justify-content: center;
        top: 8vh;
        text-align: center;
        right: 0;
        flex-direction: column;
        gap: 0;
        background: rgba(0, 0, 0, 0.95);
        border-radius: 10px;
        width: 100%;
        box-shadow: 1rem 2rem 5rem rgba(0, 0, 0, 0.387);
        transition: var(--transitionX);
        backdrop-filter: blur(4px);
    }
    .h-menu .a {
        width: 100%;
        transition: var(--transitionX);
    }
    .show__nav {
        display: flex;
        transition: var(--transitionX);
    }
    .hide__nav {
        display: none;
        transition: var(--transitionX);
    }
}

/*============ MEDIA QUERIES (SMALL DEVICES) ============*/
@media screen and (max-width: 600px) {
    .h-container {
        display: flex;
        align-items: center;
        width: 95%;

    }
    .logo1{
        justify-content: center;
        margin-left: 30%;
    }
    .nav__toggle-btn{
        margin-top: 5px;
        margin-right: -1rem;
    }
  
    .h-socials{
        display: none;
    }
    .h-container .logo1 img {
        margin-top: 10px;
    }
}