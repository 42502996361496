.store__back-image {
    text-align: center;
    justify-content: center;
    align-items: center;
    z-index: 0;
    height: 60vh;
    margin-top: 4rem;
    background: url(/public/media//backImage.jpg);
    background-attachment: fixed;
    box-shadow: rgb(0, 0, 0) inset 0px 10px 99px;
    background-size: cover;
    border-radius: 20px;
    object-fit: cover;
}

.store__back-image h2 {
    color: white;
    padding-top: 15rem;
    font-size: 4rem;
    place-items: center;
}

/*============ MEDIA QUERIES (MEDIUN DEVICES) ============*/
@media screen and (max-width: 1024px) {
    
}

/*============ MEDIA QUERIES (SMALL DEVICES) ============*/
@media screen and (max-width: 600px) {
    
}