.about__back-image {
    text-align: center;
    justify-content: center;
    align-items: center;
    z-index: 0;
    height: 60vh;
    margin-top: 4rem;
    background: url(/public/media//backImage.jpg);
    background-attachment: fixed;
    box-shadow: rgb(0, 0, 0) inset 0px 10px 99px;
    background-size: cover;
    border-radius: 20px;
    object-fit: cover;
}

.about__back-image h2 {
    color: white;
    padding-top: 15rem;
    font-size: 4rem;
    place-items: center;
}

.about {
    width: 99%;
}

.about-items {
    display: flex;
    gap: 4rem;
}

.about-images {
    display: flex;
    gap: 1rem;
}

.image1 {
    height: 59.4vh;
}

.image2 , .image3 {
    width: 16rem;
    margin-bottom: 1.8rem;
}

.image1 , .image2 , .image3 {
    cursor: pointer;
    border-radius: 10px;
    transition: var(--transition);
}

.about-images img:hover {
    transform: scale(0.9);
    transition: var(--transition);
}

.about-images .column-image {
    display: flex;
    flex-direction: column;
}

.about-text__details {
    display: flex;
}

.text__details p {
    width: 92%;
}

.about-text p {
    margin-bottom: 2rem;
}

.about-text__button {
    display: flex;
    margin-top: 2rem;
    padding: 2rem;
    gap: 18rem;
    background: white;
    border-radius: 30px;
    margin-bottom: 2rem;
}

.about-text__button .text__details .p {
    width: 160%;
}

/*============ MEDIA QUERIES (MEDIUN DEVICES) ============*/
@media screen and (max-width: 1024px) {
    .about-items {
        flex-direction: column;
        align-items: center;
    }
    .about-text__button .text__details .p {
        width: 100%;
    }
}

/*============ MEDIA QUERIES (SMALL DEVICES) ============*/
@media screen and (max-width: 600px) {
    .about-items {
        text-align: center;
        flex-direction: column;
        align-items: center;
    }
    .about {
        width: 100%;
    }
    .image1 {
        height: 45vh;
        width: 10rem;
    }
    .about-container {
        margin-bottom: 8rem;
    }
    .image2 , .image3 {
        width: 10rem;
    }
    .about-text__button .text__details .p {
        width: 100%;
    }
    .text__details p {
        width: 100%;
    }
    .about-text__details {
        display: flex;
        flex-direction: column;
    }
    .about-text__button {
        text-align: center;
        gap: 2rem;
        flex-direction: column;
        
    }
}