.slidxer-container {
    display: grid;
    place-items: center;
    border-radius: 30px;
    margin-top: 4rem;
    margin-bottom: 4rem;
    background: black;
    transition: var(--transitionX);
}

.slidexr {
    height: 150px;
    margin: auto;
    position: relative;
    width: 90%;
    display: grid;
    place-items: center;
    overflow: hidden;
    transition: var(--transitionX);
}

.slide-track {
    display: flex;
    width: calc(250px * 18);
    animation: scroll 40s linear infinite;
    transition: var(--transitionX);
}

@keyframes scroll {
    0% {
        transform: translateX(0);
        transition: var(--transitionX);
    }
    100% {
        transform: translateX(calc(-250px * 9));
        transition: var(--transitionX);
    }
}

.slidex {
    height: 150px;
    width: 150px;
    display: flex;
    align-items: center;
    padding: 15px;
    perspective: 100px;
    transition: var(--transition);
}

.slidex img {
    width: 100%;
    transition: var(--transitionX);
}

.slidex img:hover {
    scale: 1.2;
}

/* GRADIENT SHADOWS */

.slidexr::before,
.slidexr::after {
    background: linear-gradient(to right, rgb(0, 0, 0) 0%, rgba(2, 0, 0, 0) 100%);
    content: '';
    height: 100%;
    position: absolute;
    width: 20%;
    z-index: 2;
    transition: var(--transition);
}

.slidexr::before {
    left: 0;
    top: 0;
}

.slidexr::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

/*============ MEDIA QUERIES (MEDIUN DEVICES) ============*/
@media screen and (max-width: 1024px) {
    
}

/*============ MEDIA QUERIES (SMALL DEVICES) ============*/
@media screen and (max-width: 600px) {
    .slidexr::before,
    .slidexr::after {
    width: 20%;
    }
    .slidexr {
        height: 150px;
        margin: auto;
        position: relative;
        width: 86%;
        display: grid;
        place-items: center;
        overflow: hidden;
        transition: var(--transitionX);
    }
}
