@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin:  0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    scroll-behavior: smooth;
} 

:root {
    --color-bg: #030104;
    --color-primary: #E32C2C;
    --color-gradient: linear-gradient(90deg, #1f2a52 0%, #9d00e7 100%);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --transitionX: 0.5s ease;
    --transition: 1s ease;

    --container-width-lg: 98%;
    --container-width-md: 80%;
    --container-width-sm: 92%;
}

html {
    scroll-behavior: smooth;
}

html::-webkit-scrollbar {
    width: 0.4rem;
    background: transparent;
}
html::-webkit-scrollbar-thumb {
    border-radius: 5rem;
    background: rgba(0, 0, 0, 0.434);
}

html::-webkit-scrollbar-button {
    background: transparent;
    width: 10px;
}

body {
    font-family: 'Work Sans', sans-serif;
    line-height: 1.7;
}

.loading {
    margin: 4rem auto;
    align-items: center;
    place-items: center;
    width: 100%;
    transition: var(--transition);
}

section {
    margin-top: 6rem;
    transition: var(--transition);
}

li {
    font-size: 14px;
}

p {
    font-size: 14px;
    opacity: 0.7;
}

h4 {
    text-decoration: none;
    position: relative;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 1rem;
}


h4::after {
    content: ' ';
    width: 14%;
    height: 3.4px;
    background: red;
    position: absolute;
    border-radius: 1px;
    bottom: 0;
    left: 0;
    transition: var(--transition);
}

h4:hover::after {
    width: 30%;
}

.btn-secundary {
    padding: 8px 12px;
    cursor: pointer;
    color: var(--color-white);
    font-size: 14px;
    font-weight: bold;
    border-radius: 50px;
    background: var(--color-primary);
    transition: var(--transition);
}

.btn-secundary:hover {
    transition: var(--transitionX);
    background: black;
}

/*========== GERAL STYLES ==========*/
.container-width {
    width: var(--container-width-lg);
    margin: 0 auto;
    transition: var(--transition);
}
.container {
    width: var(--container-width-md);
    margin: 0 auto;
    transition: var(--transition);
}

.container-width-sm {
    width: var(--container-width-sm);
    margin: 0 auto;
    transition: var(--transition);
}

/*================= BTN ==================*/

/*============ MEDIA QUERIES (MEDIUN DEVICES) ============*/
@media screen and (max-width: 1024px) {
    
}

/*============ MEDIA QUERIES (SMALL DEVICES) ============*/
@media screen and (max-width: 600px) {
    p {
        font-size: 18px;
        font-weight: normal;
        opacity: 0.7;
    }
    li {
        font-size: 18px;
    }
}