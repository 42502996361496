footer {
    background: black;
}

.f-container {
    padding: 2rem 1%;
    border-radius: 20px 20px 0px 0px;
    padding-bottom: 2rem;
    display: flex;
}

.h-socials a {
    transition: var(--transitionX);
}

.h-socials a:hover {
    color: var(--color-primary);
    scale: 1.2;
}

.f-right {
    gap: 8rem;
}
.f-left {
    margin-left: 2rem;
}
.f-left img {
    width: 10rem;
    margin-bottom: 1.4rem;
}
.f-left p {
    color: white;
    width: 60%;
}
.f-right {
    display: flex;
}

.f-right h4 {
    color: white;
    margin-bottom: 1.4rem;
}

.f-details .f-opc {
    display: flex;
    flex-direction: column;
} 

.f-opc a {
    transition: var(--transitionX);
}

.f-details .f-opc a:hover {
    color: rgb(255, 255, 255);
    scale: 1.2;
}

.f-details .f-opc li {
    color: rgba(255, 255, 255, 0.581);
}

.f-details .f-opc p {
    color: rgba(255, 255, 255, 0.581);
    width: 120%;
}

.f-details .f-opc a {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.581);
}

.f-details .f-opc input {
    background: transparent;
    border: 2px solid var(--color-primary);
    border-radius: 50px;
    padding: 12px;
}

.footer__copyright {
    justify-content: center;
    margin-top: 4rem;
    text-align: center;
    transform: translate(-250px, 10px);
    color: white;
}

.footer__copyright small {
    font-size: 14px;
    background: var(--color-primary);
    padding: 5px 18px;
    border-radius: 50px;
}

/*============ MEDIA QUERIES (MEDIUN DEVICES) ============*/
@media screen and (max-width: 1024px) {
    .f-container {
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: 1fr;
    }
    .f-details .f-opc p {
        width: 98%;
    }
    .footer__copyright {
        justify-content: center;
        margin-top: 5rem;
        align-items: center;
        text-align: center;
        transform: translate(-0px, 10px);
        color: white;
    }
    .f-left {
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin-bottom: 2rem;
    }
    .f-left p {
        width: 98%;
    }
    .f-left .h-socials {
        align-items: center;
        justify-content: center;
    }
    .f-right {
        justify-content: space-between;
        gap: 8rem;
    }
}

/*============ MEDIA QUERIES (SMALL DEVICES) ============*/
@media screen and (max-width: 600px) {
    .f-container {
        padding-top: 2rem;
        padding-bottom: 2rem;
        justify-content: center;
        text-align: center;
        display: flex;
        flex-direction: column;
    }
    .f-left{
        justify-content: center;
        align-items: center;
        margin-left: 0;
        place-items: center;
        text-align: center;
    }
    .f-left p {
        color: white;
        width: 100%;
    }
    .f-right {
        display: flex;
        flex-direction: column;
    }
    .f-details .f-opc p {
        color: rgba(255, 255, 255, 0.581);
        width: 100%;
    }
    .footer__copyright {
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 4rem;
        text-align: center;
        color: white;
    }
    .p-multimedia {
        margin-bottom: 2rem;
    }
    .footer__copyright small {
        font-size: 16px;
        background: var(--color-primary);
        padding: 5px 18px;
        border-radius: 50px;
    }
    .f-details .f-opc a {
        font-size: 18px;
    }
}