.semservices {
    display: flex;
    background: var(color-white);
    margin-top: 1rem;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.072) 0px 10px 99px;
    margin-bottom: 4rem;
    transition: var(--transition);
}

.semservices-container {
    display: flex;
    padding-top: 4rem;
    gap: 18rem;
    padding-bottom: 4rem;
    transition: var(--transition);
}

 .details-other h4, p {
    margin-bottom: 1rem;
}

.semservices-container p {
    width: 180%;
}

.semservices-container h4 {
    width: 160%;
}

.semservices-right {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    transition: var(--transition);
}

.semservices-right p {
    width: 90%;
}

.semservices-logos {
    margin: -4px;
    margin-right: 4px;
    width: 40px;
}


.semservices-details .lgg {
    display: flex;
    margin-bottom: 1rem;
    transition: var(--transition);
}

/*============ MEDIA QUERIES (MEDIUN DEVICES) ============*/
@media screen and (max-width: 1024px) {
    .semservices-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .semservices-container p {
        width: 100%;
    }
    .semservices-container h4 {
        width: 10%;
    }
}

/*============ MEDIA QUERIES (SMALL DEVICES) ============*/
@media screen and (max-width: 600px) {
    .semservices-container {
        display: block;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding-top: 4rem;
        gap: 18rem;
        padding-bottom: 4rem;
    }
    .semservices-container h4 {
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 100%;
        transform: translate(0px, 0px);
        font-weight: 600;
        font-size: 22px;
    }
    .details-other {
        margin-bottom: 6rem;
    }
    .semservices-container p {
        width: 100%;
    }
    .semservices-right {
        text-align: center;
        justify-content: center;
        align-items: center;
        display: grid;
        grid-template-columns: 1fr;
        gap: 4rem;
    }
    .semservices-details .lgg{
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    h4 {
        text-decoration: none;
        text-align: center;
        justify-content: center;
        position: relative;
    }
    h4::after {
        content: ' ';
        width: 14%;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 3.4px;
        background: red;
        position: none;
        border-radius: 1px;
        bottom: 0;
        left: none;
        right: none;
        transition: var(--transition);
    }
    .p-inte{
        margin-bottom: 2rem;
    }
    h4:hover::after {
        width: 100%;
    }
}