.linkyt {
    background: url(./background.jpg) black;
    background-size: cover;
    background-attachment: fixed;
    padding: 2rem;
}

.lk-container {
    width: 95%;
    display: flex;
    min-height: 100vh;
    position: relative;
    margin: 0 auto;
}

.lk-container .linkyt-container {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    gap: 15px;
    margin: 20px auto;
}

.lk-container .linkyt-container .link-media {
    height: 260px;
    width: 100%;
    border-radius: 20px;
    border: 2px solid var(--color-white);
    box-shadow: rgba(0, 0, 0, 0.359) 10px 20px 59px;
    overflow: hidden;
    cursor: pointer;
}

.lk-container .linkyt-container .link-media :is(video, img) {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: all 0.3s linear;
}

.lk-container .linkyt-container .link-media h6 {
    position: absolute;
    left: 0;
    bottom: 5%;
    text-align: center;
    z-index: 999;
}

.lk-container .linkyt-container .link-media:hover :is(video, img) {
    scale: 1.1;
}

.lk-container .popup-media {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(8px);
    width: 100%;
    height: 100%;
}

.lk-container .popup-media :is(video, img) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 4px solid white;
    display: block;
    border-radius: 10px;
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
}

.lk-container .popup-media span {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 3rem;
    font-weight: 400;
    cursor: pointer;
    color: #fff;
    user-select: none;
}