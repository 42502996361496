*{
    box-sizing: border-box;
    margin: 0;
}
 
:root {
    --heights: 90vh;
    --widths: 100%;
}

.slider-content {
    height: var(--heights);
    width: var(--widths);
    position: relative;
    margin: auto;
    overflow: hidden;
    transition: var(--transition);
}

.slider-container {
    margin: 12px;
    transition: var(--transition);
}

.active {
    display: inline-block;
    transition: var(--transition);
}

.inactive {
    display: none;
    transition: var(--transition);
}

.slides {
    height: 72vh;
    width: var(--widths);
    position: relative;
    transition: var(--transition);

}

.slide-image {
    width: 100%;
    height: 100%;
    margin-top: -2rem;
    border-radius: 20px;
    position: absolute;
    object-fit: cover;
    box-shadow: inset rgba(0, 0, 0, 0.079) 0px 10px 99px;
}

.slide-text {
    width: 100%;
    height: 100%;
    color: white;
    font-size: 14px;
    position: absolute;
    text-align: center;
    top: 55%;
    transition: var(--transition);
}

.slide-title {
    color: white;
    width: 100%;
    font-size: 14px;
    position: absolute;
    text-align: center;
    top: 70%;
    transition: var(--transition);

}

.slide-title .ttl {
    background: var(--color-primary);
    border-radius: 50px;
    transition: var(--transition);
    padding: 4px 24px;
}

.slide-more {
    width: 100%;
    color: white;
    font-size: 14px;
    position: absolute;
    align-items: center;
    text-align: center;
    transition: var(--transition);
    top: 85%;
    z-index: 1;
}

.slide-more .saiba-mais {
    background: black;
    align-items: center;
    color: white;
    text-align: center;
    padding: 8px 12px;
    border-radius: 50px;
    transition: var(--transition);
}

.slide-more .saiba-mais img {
    width: 14px;
    padding-right: 2px;
    transition: var(--transition);
}

.slide-more .saiba-mais:hover {
    background: white;
    align-items: center;
    color: black;
    text-align: center;
    padding: 8px 12px;
    border-radius: 50px;
    transition: var(--transition);

}

.slide-text {
    top: 52vh;
    font-size: 40px;
    transition: var(--transition);
}

.prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    padding: 1rem;
    margin-top: -3rem;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    transition: var(--transition);
} 

.prev:hover, .next:hover {
    color: white;
    background: black;
    transition: all 1s ease-in;
    transition: var(--transition);
}

.next {
    right: 0;
    border-radius: 5px 0px 0px 5px;
    transition: var(--transition);
}

.all-dots {
    width: 100%;
    position: absolute;
    display: flex;
    top: 97%;
    gap: 1rem;
    justify-content: center;
    transition: var(--transitionX);
}

.dot {
    cursor: pointer;
    height: 6px;
    width: 6px;
    margin: 0px 3px;
    background: transparent;
    border-radius: 50%;
    border: black solid 0.1px;
    display: inline-block;
    transition: var(--transitionX);
}

.active-dot {
    background: black;
    width: 10px;
    height: 10px;
    margin-top: -2px;
    transition: var(--transitionX);
}

.dot:hover {
    scale: 1.8;
}

/*============ MEDIA QUERIES (MEDIUN DEVICES) ============*/
@media screen and (max-width: 1024px) {
    
}

/*============ MEDIA QUERIES (SMALL DEVICES) ============*/
@media screen and (max-width: 600px) {
    .all-dots {
        width: 100%;
    }
    .slide-text {
        top: 74%;
    }
    .slide-more {
        width: 100%;
        font-size: 18px;
    }
    .slide-title {
        width: 100%;
        top: 70%;
        font-size: 18px;
    }
    .slide-image {
        box-shadow: inset black 10px 10px 59px;
    }
}