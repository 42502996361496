.recrutamento__back-image {
    text-align: center;
    justify-content: center;
    align-items: center;
    z-index: 0;
    height: 34vw;
    margin-top: 4rem;
    background: url(../assets/imagens//slide5.jpeg);
    background-attachment: fixed;
    background-size: 100%;
    border-radius: 20px;
    object-fit: cover;
}

.recrutamento__back-image h2 {
    color: white;
    padding-top: 15rem;
    font-size: 4rem;
    place-items: center;
}

.recrutamento-container {
    margin-top: 4rem;
}

.recrutamento-container img {
    width: 31%;
}