.contact__back-image {
    text-align: center;
    justify-content: center;
    align-items: center;
    z-index: 0;
    height: 70vh;
    margin-top: 4rem;
    background: url(/public/media//backImage.jpg);
    background-attachment: fixed;
    box-shadow: rgb(0, 0, 0) inset 0px 10px 99px;
    background-size: cover;
    border-radius: 20px;
    object-fit: cover;
}

.contact__back-image h2 {
    color: white;
    padding-top: 15rem;
    font-size: 4rem;
    place-items: center;
}

.contact {
    box-shadow: rgba(0, 0, 0, 0.072) 0px 10px 99px;
}

.contact__container {
    display: grid;
    grid-template-columns: 30% 58%;
    background: var(--color-white);
    padding: 2rem;
    border-radius: 30px;
    margin-top: 4rem;
    margin-bottom: 4rem;
    gap: 12%; 
}

.contact__options {
    display: flex;
    flex-direction: column;
    margin-top: 7.8rem;
    gap: 1.8rem;
    margin-bottom: 4rem;
}

.contact__option {
    display: flex;
    padding: 1.2rem;
    gap: 1rem;
    border-radius: 1.2rem;
    margin-bottom: -1rem;
    border-left: 2px solid var(--color-primary);
    box-shadow: rgba(0, 0, 0, 0.071) 0px 10px 99px;
    font-weight: bold;
    transition: var(--transitionX);
}

.contact__option:hover {
    transition: var(--transitionX);
    scale: 0.9;
}

.contact__option a {
    font-weight: normal;
    padding: 6px 12px;
    border-radius: 10px;
    font-size: 14px;
    background: var(--color-primary);
    color: white;
    transition: var(--transition);
}

.contact__option a:hover {
    background: black;
}

.contact__option-icon {
    margin-top: 0.8rem;
    color: var(--color-primary);
    display: inline-block;
    font-size: 4rem;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border: 2px solid var(--color-primary);
    text-decoration: none;
    background: transparent;
    border-radius: 20px;
    resize: none;
    color: black;
    transition: var(--transition);

}

button {
    color: var(--color-white);
}

form h3 {
    margin-bottom: -14px;
}

/*============ MEDIA QUERIES (MEDIUN DEVICES) ============*/
@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    .contact__container{
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .contact__options{
        display: flex;
        flex-direction: none;
    }
}

/*============ MEDIA QUERIES (SMALL DEVICES) ============*/
@media screen and (max-width: 600px) {
    .contact__container {
        width: var(--container-width-sm);
        margin-top: 1rem;
    }
    .contact__option:hover{
        scale: none;
    }
    .contact__options{
        margin-top: 0rem;
    }
    .contact__option {
        display: block;
        padding: 1.2rem;
        border-radius: 1.2rem;
        border-left: 1px solid var(--color-primary);
        text-align: center;
        font-weight: normal;
        transition: var(--transition);
        box-shadow: rgba(0, 0, 0, 0.246) 0px 0px 30px;
    }
    form h3, p {
        text-align: center;
    }
} 